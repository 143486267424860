<template>
  <h1>Human To Cheez-It Converter</h1>

  <form>
    <input type="text" name="weight" v-model="weight">lbs
  </form>
  <h3 v-if="checkNum">You Are Equivilant to</h3> 
  <h3>
    <strong>{{ cheezits }}</strong>
  </h3> 
  <h3>Cheez-Its</h3>
  <h3 v-if="!checkNum">Please use numbers only</h3>
</template>

<script>
import { ref, computed } from 'vue'
export default {
  name: 'App',
  meta:{
    title: 'Cheezit To Human Calculator'
  },
  setup() {
    const weight = ref('0')

    //calculate cheezits
    const cheezits = computed(() => {
      let w = weight.value !== '' ? parseInt(weight.value) : 0
      return (Math.round((w * 453.592) * 24)).toLocaleString('en-US')
    })

    //Check if number
    const checkNum = computed(() => {
      if(!isNaN(weight.value) || weight.value === ''){
        return true
      }else{
        return false
      }
    })

    return {
      weight,
      cheezits,
      checkNum
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Oswald:wght@400;600&display=swap');
$fonts: 'Bebas Neue', Oswald,Avenir, Helvetica, Arial, sans-serif;
$red: #BD0706;
$orange: #F47B20;
$yellow: #FBF428;

#app {
  font-family: $fonts;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  margin-top: 60px;
  padding: 25px;
  width: 95%;
  max-width: 450px;
  border-radius: 20px;
  box-sizing: border-box;
  text-shadow: 1px 1px 0px darken($orange, 30%);
}
body {
  // background: url('/cheezit.jpg');
  background: radial-gradient(lighten($red, 30%), $red);
  margin: 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  box-sizing: border-box;
  font-size: 18px;
}

strong{
  color: $yellow;
  font-size: 2.5rem;
  text-shadow: 1px 1px 0px darken($orange, 30%);
  padding: 20px;
  margin: 0;
}

input{
  border: none;
  outline: none;
  border-bottom: solid #fff 3px;
  background: none;
  font-size: 3rem;
  width: 200px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-family: $fonts;


  &:focus{
    border-color: $yellow;
  }
}
</style>
